import React from "react"
import { graphql, Link } from 'gatsby'
import Img from "gatsby-image"
import styled from 'styled-components'
import Layout from "../components/Layout/Layout"
import PageHeader from '../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../components/Layout/Section/SectionPageMargin'
import Heading2 from '../components/Type/Heading2'
import Heading4 from '../components/Type/Heading4'
import Paragraph1 from '../components/Type/Paragraph1'
import SEO from "../components/Seo/Seo"

const MakePage = ({ location, data }) => {

  const activeMakes = data.allValuation.distinct.map((item, i) => {

      let makeElement = null;

    if (item === "Audi") makeElement =
      <MakeLink key={item+i} to={"/price/audi/"}>
        <Img fixed={data.audiImg.childImageSharp.fixed} style={{ display: 'block' }} />
        <span>Audi</span>
      </MakeLink>;

    if (item === "BMW") makeElement =
      <MakeLink to={"/price/bmw/"}>
        <Img fixed={data.bmwImg.childImageSharp.fixed} style={{ display: 'block' }} />
        <span>BMW</span>
      </MakeLink>;

    if (item === "Land Rover") makeElement =
      <MakeLink to={"/price/land-rover/"}>
        <Img fixed={data.landRoverImg.childImageSharp.fixed} style={{ display: 'block' }} />
        <span>Land Rover</span>
      </MakeLink>;

    if (item === "Maserati") makeElement =
      <MakeLink to={"/price/maserati/"}>
        <Img fixed={data.maseratiImg.childImageSharp.fixed} style={{ display: 'block' }} />
        <span>Maserati</span>
      </MakeLink>;

    if (item === "Mercedes-Benz") makeElement =
      <MakeLink to={"/price/mercedes-benz/"}>
        <Img fixed={data.mercedesBenzImg.childImageSharp.fixed} style={{ display: 'block' }} />
        <span>Mercedes-Benz</span>
      </MakeLink>;

    if (item === "Porsche") makeElement =
      <MakeLink to={"/price/porsche/"}>
        <Img fixed={data.porscheImg.childImageSharp.fixed} style={{ display: 'block' }} />
        <span>Porsche</span>
      </MakeLink>;

    if (item === "Tesla") makeElement =
      <MakeLink to={"/price/tesla/"}>
        <Img fixed={data.teslaImg.childImageSharp.fixed} style={{ display: 'block' }} />
        <span>Tesla</span>
      </MakeLink>;

    if (item === "Toyota") makeElement =
      <MakeLink to={"/price/toyota/"}>
        <Img fixed={data.toyotaImg.childImageSharp.fixed} style={{ display: 'block' }} />
        <span>Toyota</span>
      </MakeLink>;

    if (item === "Volvo") makeElement =
      <MakeLink to={"/price/volvo/"}>
        <Img fixed={data.volvoImg.childImageSharp.fixed} style={{ display: 'block' }} />
        <span>Volvo</span>
      </MakeLink>;

      return makeElement
  });

  return (
    <Layout>

      <SEO title="Price guide for prestige cars in Australia"
        description="How much are prestige cars worth in Australia? Find out here."
        location={location}
      />

      <PageHeader marginBottom="10px"
        title="Price Guide for prestige cars in Australia"
        text="How much are prestige cars worth in Australia? Find out here."
      />

      <SectionPageMargin marginBottom="40px">

        <Heading2>Valuations</Heading2>

        <Paragraph1>
          At Summon we deal with a wider range of car brands than shown below. However, the following
          car makes have sufficient volumes of sales to provide a useful price guide.  We have omitted
          vehicles (make-model-badge-series-year combination) where we have less than 8 sample sales
          in the last 12 months.
      </Paragraph1>

        <Heading4>
          Please select a make
      </Heading4>

        <MakeContainter>

          {activeMakes}

        </MakeContainter>

      </SectionPageMargin>


    </Layout>
  )
}

export default MakePage

const MakeContainter = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 40px;

  @media (max-width: 600px) {
    justify-content: center;
  }
`;

const MakeLink = styled(Link)`
	border: 1px solid #CCCCCC;
	border-radius: 5px;
	text-align: center;
	padding: 10px;
	background: white;
	margin: 10px;

	&:hover {
		box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
	  }
`

export const PageQuery = graphql`
  query {

    allValuation {
      distinct(field: make)
    }

    audiImg: file(relativePath: { eq: "car-logos/audi.png" }) {
      childImageSharp {
        fixed(width: 150) {
        ...GatsbyImageSharpFixed
        }
      }
    }

    bmwImg: file(relativePath: { eq: "car-logos/bmw.png" }) {
      childImageSharp {
        fixed(width: 150) {
        ...GatsbyImageSharpFixed
        }
      }
    }

    landRoverImg: file(relativePath: { eq: "car-logos/land-rover.png" }) {
      childImageSharp {
        fixed(width: 150) {
        ...GatsbyImageSharpFixed
        }
      }
    }

    maseratiImg: file(relativePath: { eq: "car-logos/maserati.png" }) {
      childImageSharp {
        fixed(width: 150) {
        ...GatsbyImageSharpFixed
        }
      }
    }

    mercedesBenzImg: file(relativePath: { eq: "car-logos/mercedes-benz.png" }) {
      childImageSharp {
        fixed(width: 150) {
        ...GatsbyImageSharpFixed
        }
      }
    }

    porscheImg: file(relativePath: { eq: "car-logos/porsche.png" }) {
      childImageSharp {
        fixed(width: 150) {
        ...GatsbyImageSharpFixed
        }
      }
    }
    
    teslaImg: file(relativePath: { eq: "car-logos/tesla.png" }) {
      childImageSharp {
        fixed(width: 150) {
        ...GatsbyImageSharpFixed
        }
      }
    }

    toyotaImg: file(relativePath: { eq: "car-logos/toyota.png" }) {
      childImageSharp {
        fixed(width: 150) {
        ...GatsbyImageSharpFixed
        }
      }
    }

    volvoImg: file(relativePath: { eq: "car-logos/volvo.png" }) {
      childImageSharp {
        fixed(width: 150) {
        ...GatsbyImageSharpFixed
        }
      }
    }
    
  }`

